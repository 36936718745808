import { graphql, Link, useStaticQuery } from "gatsby";
import * as React from "react";
import { lazy, Suspense, useEffect, useState } from "react";
import BurgerDotsOpen from "./svg/BurgerDotsOpen";
import CloseCross from "./svg/CloseCross";
import LogoEsa from "./svg/LogoEsa";
import { GenericUri, IssuePageUri } from "../../utils/uriFactory";
const LazySearchAutocomplete = lazy(
  () => import("../../core/Search/SearchAutocomplete")
);

export type MainNavigationProps = {
  pageType?: string;
  pageSlug?: string | null;
  shortTitle?: string;
  issueSlug?: string;
  issueTitle?: string;
  issueIndex?: number;
  uri?: string;
};
const MainNavigation = ({
  pageType = "page",
  shortTitle,
  issueSlug,
  issueIndex,
  issueTitle,
  uri,
}: MainNavigationProps) => {
  const {
    latest: { nodes },
  } = useStaticQuery<Queries.LatestIssueQuery>(graphql`
    query LatestIssue {
      latest: allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "issue" } } }
        sort: { frontmatter: { index: DESC } }
      ) {
        nodes {
          frontmatter {
            title
            slug
            index
          }
        }
      }
    }
  `);
  const latestIssue = { ...nodes[0]?.frontmatter };
  const {
    title: latestTitle,
    slug: latestSlug,
    index: latestIndex,
  } = latestIssue;

  const toggleScrollUpDown = () => {
    window?.scrollTo({
      top:
        window?.scrollY === 0
          ? document.body.scrollHeight + window.innerHeight
          : 0,
      behavior: "smooth",
    });
  };
  const clickScroll = { onClick: () => toggleScrollUpDown() };

  if (pageType === "issue") shortTitle = "Sommaire";
  const linkToSummary = pageType != "issue";
  const toSummary = IssuePageUri({ issue: issueSlug || latestSlug });
  const indexWithPad = (issueIndex || latestIndex || 1)
    .toString()
    .padStart(2, "0");
  const title = `${indexWithPad} | ${issueTitle || latestTitle}`;
  const linkToSummaryProps = {
    className: "sub-nav__el issue-title",
    children: (
      <>
        {`${indexWithPad} | `}
        <span className={"font-secondary"}>{issueTitle || latestTitle}</span>
      </>
    ),
  };
  const linkToSummaryEl = linkToSummary ? (
    <Link {...linkToSummaryProps} to={toSummary} />
  ) : (
    <span {...linkToSummaryProps} {...clickScroll} />
  );

  const issueStaticNavElements = [
    { slug: "", label: "Sommaire" },
    { slug: "contributeur-rices", label: "Contributeur·rices" },
  ];

  let currentLabel = shortTitle;
  if (!shortTitle) {
    switch (pageType) {
      case "article":
        currentLabel = "Article";
        break;
      case "issue":
        currentLabel = "Sommaire";
        break;
      case "people":
        currentLabel = "Biographie";
        break;
      case "appendix":
        currentLabel = title;
        break;
      case "none":
        currentLabel = "À la une";
        break;
      default:
        currentLabel = "--";
        break;
    }
  }

  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleMobileMenu = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const onClickDotMenu = () => {
    if (window.innerWidth < 1080) {
      toggleMobileMenu();
    } else {
      toggleScrollUpDown();
    }
  };

  useEffect(() => {
    setSidebarVisible(false);
  }, [uri]);

  const HomeLink = (
    <Link className={"sub-nav__el home-link"} to={"/"}>
      la mire
      <span className={"sep"} />
    </Link>
  );

  const SearchInput = (
    <div className={"sub-nav__el search"}>
      <Suspense>
        <LazySearchAutocomplete />
      </Suspense>
    </div>
  );

  const IssueLinks = (props: { className: string }) => (
    <div {...props}>
      {issueStaticNavElements.map(({ slug, label }, key) => {
        const to = IssuePageUri({ issue: issueSlug || latestSlug, slug });
        return uri === to ? null : (
          <Link
            className={"sub-nav__el"}
            {...{ key, to, children: label }}
            children={label}
          />
        );
      })}
    </div>
  );

  const GeneralLinks = (
    <div className={"sub-nav__dropdown sub-nav__general-links"}>
      {[
        {
          label: "À propos",
          to: GenericUri({ slug: "a-propos" }),
        },
        {
          label: "Colophon",
          to: GenericUri({ slug: "colophon" }),
        },
        {
          el: ({ key }: { key: number }) => {
            return (
              <a
                {...{ key }}
                className={"sub-nav__el link-to-esa"}
                href={"https://www.esa-n.info"}
                target={"_blank"}
                rel={"noopener"}
              >
                <LogoEsa />
                <span>esa-n.info</span>
              </a>
            );
          },
        },
      ].map(({ label, to, el }, i) => {
        return !!el ? (
          el({ key: i })
        ) : (
          <Link className={"sub-nav__el"} key={i} to={to} children={label} />
        );
      })}
    </div>
  );

  return (
    <header className={"main-navigation"}>
      <div className={"main-navigation__part main-navigation__left"}>
        <div className={"sub-nav"}>{HomeLink}</div>
      </div>
      <div className="main-navigation__part main-navigation__center">
        <div className={"sub-nav sub-nav--primary"}>
          {linkToSummaryEl}
          <div className={"sub-nav__dropdown"}>{SearchInput}</div>
        </div>

        <nav className={"sub-nav sub-nav--secondary"}>
          <div
            className={"sub-nav__el neg main-navigation__current"}
            {...clickScroll}
          >
            {currentLabel}
          </div>
          {
            <IssueLinks
              {...{ className: "sub-nav__dropdown sub-nav__issue-links" }}
            />
          }
        </nav>
      </div>
      <div className="main-navigation__part main-navigation__right">
        <nav className={"sub-nav"}>
          <button
            type={"button"}
            className={"sub-nav__el main-navigation__global"}
            onClick={onClickDotMenu}
            aria-label={"Menu"}
          >
            <BurgerDotsOpen />
          </button>
          {GeneralLinks}
        </nav>
      </div>
      <div
        className={`main-navigation__sidebar ${
          sidebarVisible ? "main-navigation__sidebar--visible" : ""
        }`}
      >
        <div className="sidebar">
          <div className={"sidebar__top"}>
            {HomeLink}
            {linkToSummaryEl}
            <button
              type={"button"}
              className={"sub-nav__el sidebar__close"}
              onClick={toggleMobileMenu}
              role={"button"}
              aria-label={"Fermer le menu"}
            >
              <CloseCross />
            </button>
          </div>
          <div className="sidebar__main">
            {SearchInput}
            <div className="sidebar__sep" />
            {<IssueLinks {...{ className: "sub-nav__issue-links" }} />}
            <div className="sidebar__sep sidebar__sep--black" />
            {GeneralLinks}
            <div className="sidebar__sep sidebar__sep--black" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default MainNavigation;
