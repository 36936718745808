import { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from "gatsby";
import React, { PropsWithChildren } from "react";
import BaseLayout from "../layouts/BaseLayout";
import { DefaultPageContext } from "../head/DefaultHead";
import { PageViews, CustomEvent } from "@piwikpro/react-piwik-pro";

export type WrapPageElementArgs<
  DataType = Record<string, unknown>,
  PageContextType = Record<string, unknown>
> =
  | WrapPageElementNodeArgs<DataType, PageContextType>
  | WrapPageElementBrowserArgs<DataType, PageContextType>;

export type LayoutProps<
  DataType = Record<string, unknown>,
  PageContextType = Record<string, unknown>
> = PropsWithChildren<WrapPageElementArgs<DataType, PageContextType>>;
const wrapPageElement = (
  wrapPageElementArgs: WrapPageElementArgs<any, DefaultPageContext>
) => {
  const { element: children, ...restProps } = wrapPageElementArgs;
  // @ts-ignore
  return <BaseLayout {...restProps}>{children}</BaseLayout>;
};

export default wrapPageElement;
