type IssueProps = {
  issue?: string | null;
};
type SlugProps = {
  slug?: string | null;
};
type ParentProps = {
  parent?: string | null;
};
type IssuePageProps = IssueProps & SlugProps;
type ArticleProps = IssueProps & ParentProps & SlugProps;
const ArticleUri = ({ issue, slug, parent }: ArticleProps) => {
  const parentPrefix = !!parent ? `${parent}/` : "";
  if (!issue || !slug)
    throw new Error(`Issue "${issue}" and slug "${slug}" are required`);
  return `/${issue}/article/${parentPrefix}${slug}/`;
};

const IssuePageUri = ({ issue, slug }: IssuePageProps) => {
  if (!issue) throw new Error("Issue is required");
  return `/${issue}/${!!slug ? slug + "/" : ""}`;
};
const PeopleUri = ({ slug }: SlugProps) => {
  if (!slug) throw new Error("People slug is required");
  return `/people/${slug}/`;
};
const GenericUri = ({ slug }: SlugProps) => {
  return !!slug ? `/${slug}/` : "/";
};

export { ArticleUri, IssuePageUri, PeopleUri, GenericUri };
