import React from "react";

type BurgerDotsOpenProps = {
  fill?: string;
  arialLabel?: string;
};
const BurgerDotsOpen = ({ fill, arialLabel }: BurgerDotsOpenProps) => {
  const useProps = {
    href: "#burger-dots-open-c",
  };
  return (
    <svg
      className="svg svg--burger-dots-open"
      aria-label={arialLabel}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <circle id={"burger-dots-open-c"} cx="24" cy="24" r="6" {...{ fill }} />
      </defs>
      <use {...useProps} className={"c1"} />
      <use {...useProps} className={"c2"} />
      <use {...useProps} className={"c3"} />
    </svg>
  );
};

export default BurgerDotsOpen;
