import React, { PropsWithChildren } from "react";
import "../../styles/Main.scss";
import { DefaultPageContext } from "../head/DefaultHead";
import MainNavigation from "../articles/parts/MainNavigation";
import { WrapPageElementArgs } from "../gatsby/WrapPageElement";

type BaseLayoutProps<PageContext = DefaultPageContext> = PropsWithChildren<
  Omit<WrapPageElementArgs<any, PageContext>, "element">
>;
const BaseLayout = (wrapPageElementArgs: BaseLayoutProps) => {
  const { pageContext, location } = wrapPageElementArgs.props;
  const { children } = wrapPageElementArgs;
  const uri = location?.pathname || "";
  const { title, type, short_title, issue, slug: pageSlug } = pageContext;
  let articleTitle = title || "";
  let pageType = type || "none",
    shortTitle = short_title || title || "",
    issueSlug = issue || "";
  return (
    <div className={"site"}>
      <MainNavigation
        {...{ pageType, pageSlug, articleTitle, shortTitle, issueSlug, uri }}
      />
      <div className={"page"}>{children}</div>
    </div>
  );
};

export default BaseLayout;
