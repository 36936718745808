const SITE_NAME = "la mire";
const LABORATORY_NAME = "EsäLab​|​Recherche";
const SCHOOL_NAME = "École supérieure d’art | Dunkerque-Tourcoing";
const SCHOOL_SHORT_NAME = "Esä";
const SCHOOL_URL = "https://esa-n.info/";
const SCHOOL_SAME_AS = [
  "https://www.instagram.com/esa_tourcoing/",
  "https://www.instagram.com/view_esa_dk/",
  "https://www.facebook.com/ESADKTG",
];

const SITE_HOSTNAME = "lamire.esa-n.info";
const SITE_URL = "https://" + SITE_HOSTNAME + "/";
const SITE_BIRTH_DATE = "2023-12-07T14:00:00+01:00";
const BREADCRUMB_NAME = "Fil d’ariane";

const BREADCRUMB_ROOT_NAME = "la mire";
const BREADCRUMB_ROOT_ITEM = {
  "@type": "ListItem",
  position: 1,
  name: BREADCRUMB_ROOT_NAME,
  item: { "@id": SITE_URL + "/" },
};

const JSON_LD_CONTEXT = {
  "@context": "https://schema.org",
};

const JSON_LD_BREADCRUMB_LIST = {
  ...JSON_LD_CONTEXT,
  "@type": "BreadcrumbList",
  name: BREADCRUMB_NAME,
};

const JSON_LD_LIST_ITEM = {
  ...JSON_LD_CONTEXT,
  "@type": "ListItem",
};
const CONTACT_EMAIL = "esalab@esa-n.info";

const JSON_LD_ORGANIZATION = {
  "@context": "https://schema.org",
  "@type": "ResearchOrganization",
  name: SITE_NAME,
  alternateName: LABORATORY_NAME,
  url: SITE_URL,
  logo: SITE_URL + "/img/icon.png",
  email: CONTACT_EMAIL,
  sameAs: [],
  parentOrganization: {
    "@type": "EducationalOrganization",
    name: SCHOOL_NAME,
    alternateName: SCHOOL_SHORT_NAME,
    url: SCHOOL_URL,
    logo: SITE_URL + "/img/logo-esa-96.svg",
    place: {},
    sameAs: SCHOOL_SAME_AS,
  },
};

export {
  SITE_HOSTNAME,
  SITE_URL,
  SITE_NAME,
  SITE_BIRTH_DATE,
  CONTACT_EMAIL,
  SCHOOL_URL,
  SCHOOL_NAME,
  SCHOOL_SHORT_NAME,
  SCHOOL_SAME_AS,
  LABORATORY_NAME,
  JSON_LD_ORGANIZATION,
  JSON_LD_BREADCRUMB_LIST,
  JSON_LD_LIST_ITEM,
  BREADCRUMB_ROOT_NAME,
  BREADCRUMB_NAME,
  BREADCRUMB_ROOT_ITEM,
};
