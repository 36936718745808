import PiwikPro from "@piwikpro/react-piwik-pro";
import { GatsbyBrowser } from "gatsby";
import { SITE_HOSTNAME } from "../../constants";

const onClientEntry: GatsbyBrowser["onClientEntry"] = (props) => {
  const GATSBY_PIWIKPRO_CONTAINER_ID =
    process.env.GATSBY_PIWIKPRO_CONTAINER_ID || "";
  const GATSBY_PIWIKPRO_CONTAINER_URL =
    process.env.GATSBY_PIWIKPRO_CONTAINER_URL || "";
  const hostname = window.location.hostname;
  if (hostname !== SITE_HOSTNAME) {
    console.info(
      "Piwik Pro tracking is disabled in non-production environment."
    );
  } else if (!GATSBY_PIWIKPRO_CONTAINER_ID || !GATSBY_PIWIKPRO_CONTAINER_URL) {
    console.warn(
      "Missing Piwik Pro container ID or URL, skipping initialization."
    );
  } else {
    console.info(`Initialize Piwik Pro ${GATSBY_PIWIKPRO_CONTAINER_URL}`);
    PiwikPro.initialize(
      GATSBY_PIWIKPRO_CONTAINER_ID,
      GATSBY_PIWIKPRO_CONTAINER_URL
    );
  }
};

export default onClientEntry;
