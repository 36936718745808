import React from "react";

type CloseCrossProps = {
  stroke?: string;
  arialLabel?: string;
};
const CloseCross = ({ stroke, arialLabel }: CloseCrossProps) => {
  const pathProps = {
    stroke,
  };
  return (
    <svg
      {...{ "aria-label": arialLabel, className: "svg svg--close-cross" }}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 14L35 35" {...pathProps} className={"l1"} />
      <path d="M35 14L14 35" {...pathProps} className={"l2"} />
    </svg>
  );
};

export default CloseCross;
